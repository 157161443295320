* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Poppins', sans-serif;
    background: #f4f7fc;
    color: #333;
  }
  
  .profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(145deg, #eef2f6, #cfd9e3);
  }
  
  .profile-card {
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
    width: 400px;
    padding: 2.5rem;
    transition: all 0.3s ease;
  }
  
  .profile-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 35px rgba(0, 0, 0, 0.15);
  }
  
  .profile-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .profile-image {
    font-size: 90px;
    color: #ffffff;
    background-color: #007bff;
    border-radius: 50%;
    padding: 25px;
    margin-bottom: 1.5rem;
    box-shadow: 0 8px 20px rgba(0, 123, 255, 0.2);
  }
  
  h2 {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin-bottom: 5px;
  }
  
  .profile-info {
    margin-top: 1.5rem;
    text-align: center;
  }
  
  p {
    font-size: 16px;
    color: #666;
    margin-bottom: 1rem;
  }
  
  .edit-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 12px 20px;
    border-radius: 25px;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.3s ease;
  }
  
  .edit-button:hover {
    background-color: #0056b3;
  }
  
  .edit-profile-form {
    margin-top: 2rem;
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-size: 14px;
    color: #555;
    margin-bottom: 6px;
  }
  
  input {
    width: 100%;
    padding: 12px 15px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 15px;
    transition: border-color 0.3s ease;
  }
  
  input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .save-button {
    width: 100%;
    padding: 12px 0;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .save-button:hover {
    background-color: #218838;
  }
  
  .message {
    margin-top: 1rem;
    font-size: 14px;
    color: #28a745;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .profile-card {
      width: 90%;
      padding: 2rem;
    }
  }
  