/* ViewAwards.css */

.view-awards {
    max-width: 1200px;
    margin: 50px auto;
    padding: 20px;
  }
  
  .view-awards h2 {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
  }
  
  .award-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .award-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .award-card:hover {
    transform: translateY(-10px);
  }
  
  .award-card img {
    max-width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  .award-card h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .award-card p {
    font-size: 14px;
    color: #777;
  }
  
  .award-card .award-year {
    font-weight: bold;
    color: #555;
  }
  
  .card-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
  }
  
  .update-btn, .delete-btn {
    background-color: #f04f4f;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .update-btn {
    background-color: #4caf50; /* Update button green */
  }
  
  .delete-btn {
    background-color: #f04f4f; /* Delete button red */
  }
  
  .update-btn:hover, .delete-btn:hover {
    opacity: 0.8;
  }
  /* ViewAwards.css */

/* Other existing styles for the award cards... */

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .modal-content h3 {
    margin-bottom: 20px;
  }
  
  .modal-content label {
    display: block;
    margin: 10px 0 5px;
  }
  
  .modal-content input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .modal-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modal-buttons button:first-child {
    background-color: #4caf50;
    color: white;
  }
  
  .modal-buttons button:last-child {
    background-color: #f44336;
    color: white;
  }
  