.view-faqs-container {
    padding: 20px;
  }
  
  .faqs-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .faqs-table th, .faqs-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .faqs-table th {
    background-color: #f2f2f2;
  }
  
  .delete-button, .update-button {
    margin: 5px;
    padding: 5px 10px;
    background-color: #f44336;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .update-button {
    background-color: #4CAF50;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    width: 300px;
  }
  
  .save-button, .cancel-button {
    padding: 5px 10px;
    margin: 5px;
  }
  
  .cancel-button {
    background-color: #f44336;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .save-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  