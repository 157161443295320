.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #a8edea 0%, #fed6e3 100%);
  }
  .login-image {
    font-size: 5rem;
    color: #ffffff;
    background-color: #007bff;
    border-radius: 50%;
    padding: 4px;
    margin-left:6vw;
   margin-bottom: 0.5vw;
    box-shadow: 0 8px 20px rgba(0, 123, 255, 0.2);
  }
  
  .login-title{
    font-weight: bold;
    text-align: center;
    font-size: 2rem;
    color: #007bff;
  }
  .login-form {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .h2 {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .input-group {
    margin-bottom: 1rem;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .input-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  .forgot-password{
    cursor: pointer;
  }
  
  .login-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: red;
    text-align: center;
    margin-bottom: 1rem;
  }
  