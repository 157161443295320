/* Container for the form */
.add-blog-container {
    max-width: 700px;
    margin: 50px auto;
    padding: 30px;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Form header styling */
  .form-header {
    font-size: 2rem;
    color:  #020d55;
    text-align: center;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  /* Form group (each field and label) styling */
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 5px;
    display: block;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  /* Styling for textarea (for blog description) */
  .form-group textarea {
    resize: vertical;
  }
  
  /* Styling for input focus */
  .form-group input:focus,
  .form-group textarea:focus {
    outline: none;
    border-color: #c6a906;
    box-shadow: 0 0 5px rgba(242, 236, 63, 0.5);
  }
  
  /* Submit button styling */
  .submit-button {
    width: 100%;
    padding: 12px;
    background-color: #020d55;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #1b93d8 !important;
  }

  .view-button{
    width: 100%;
    padding: 12px;
    background-color: #020d55;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    margin-top: 1vw;
    transition: background-color 0.3s ease;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .add-blog-container {
      padding: 20px;
    }
  
    .form-header {
      font-size: 1.6rem;
    }
  }
  