/* ViewBlogs.css */

body {
    font-family: Arial, sans-serif;
    background-color: #f4f7fa;
    color: #333;
}

.view-blogs-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #2c3e50;
    padding: 2vw;
}

.blog-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center; /* Centering the cards */
}

.blog-card {
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    width: calc(30% - 20px); /* Adjust width based on the number of columns */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition for hover effect */
    position: relative; /* For pseudo-elements */
}

.blog-card:hover {
    transform: translateY(-5px); /* Lift effect */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
}

.blog-card h3 {
    color: #2980b9; /* Blog title color */
    margin-bottom: 10px;
}

.blog-card p {
    line-height: 1.6;
}

.blog-card button {
    background-color: #2980b9; /* Button color */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition for button */
    margin-right: 10px; /* Space between buttons */
}

.blog-card button:hover {
    background-color: #1c5985; /* Darker shade on hover */
}

.update-form {
    margin-top: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.update-form h3 {
    margin-bottom: 15px;
    color: #2980b9; /* Form title color */
}

.update-form input,
.update-form textarea {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.update-form button {
    background-color: #27ae60; /* Update button color */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    cursor: pointer;
}

.update-form button:hover {
    background-color: #219653; /* Darker shade on hover */
}

@media (max-width: 768px) {
    .blog-card {
        width: calc(100% - 20px); /* Full width on smaller screens */
    }
}
