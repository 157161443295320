.view-testimonials-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .testimonials-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .testimonials-table th, .testimonials-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .testimonials-table th {
    background-color: #f4f4f4;
    color: #333;
  }
  
  .delete-button,
  .update-button {
    padding: 5px 10px;
    margin-right: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .delete-button {
    background-color: #ff4c4c; /* Red color for delete */
    color: white;
  }
  
  .update-button {
    background-color: #007bff; /* Blue color for update */
    color: white;
  }
  
  .delete-button:hover {
    background-color: #ff1a1a; /* Darker red on hover */
  }
  
  .update-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  }
  
  .modal-content h3 {
    margin: 0 0 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input[type="text"],
  textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  textarea {
    resize: none; /* Prevents resizing of textarea */
  }
  
  .save-button,
  .cancel-button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .save-button {
    background-color: #28a745; /* Green color for save */
    color: white;
  }
  
  .cancel-button {
    background-color: #dc3545; /* Red color for cancel */
    color: white;
  }
  
  .save-button:hover {
    background-color: #218838; /* Darker green on hover */
  }
  
  .cancel-button:hover {
    background-color: #c82333; /* Darker red on hover */
  }
  