/* ForgotPassword.css */

.forgot-password-page {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 100vh;
 
    padding: 20px;
  }
  
  .illustration img {
    width: 80vh;
    max-width: 80vh;
  }
  
  

  
 .forget-title{
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: rgb(90, 90, 255);
   margin-right: 40vh;
  }
  
  p {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  .input-group label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .input-group input {
    width: 100%;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .submit-button {
    width: 100%;
    padding: 12px;
    background-color: #28a745;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .submit-button:hover {
    background-color: #218838;
  }
  
  .back-link {
    display: block;
    text-align: center;
    color: #007bff;
    text-decoration: none;
    font-size: 14px;
  }
  
  .back-link:hover {
    text-decoration: underline;
  }
  .forget-icon{
    font-size: 1.5rem;
    margin-bottom: 0.2vw;
  }
  .back-to-login{
   text-decoration: none;
   color: black !important;
   font-weight: bold;
  
  }
  
  /* Responsive */
  @media screen and (max-width: 768px) {
    .forgot-password-page {
      flex-direction: column;
      padding: 20px;
    }
  
    .form-container {
      width: 100%;
    }
  
    .illustration img {
      max-width: 250px;
      margin-bottom: 20px;
    }
  }
  