/* AddAward.css */

.add-award-form {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .add-award-form h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .add-award-form form div {
    margin-bottom: 15px;
  }
  
  .add-award-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
  }
  
  .add-award-form input[type="text"],
  .add-award-form input[type="file"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .add-award-form button {
    width: 100%;
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
  }
  
  .award-btn {
    background-color: #45a049;
  }
  
  .viewaward-btn {
    display: block;
    width: 100%;
    margin-top: 10px;
    text-align: center;
    padding: 10px;
    background-color: #2196f3 !important;
    color: white;
    text-decoration: none ;
    border-radius: 4px;
  }
  
  .award-btn:hover {
    background-color: #1976d2;
  }
  