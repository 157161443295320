/* Center the menu button */
.navbar-container {
    padding: 10px;
    background-color: #007bff;
    display: flex;
    text-align: left;
    justify-content: space-between;
    align-items: center;
    padding: 10px; /* Adjust padding as needed */
}

.menu-button {
    background-color: #ffffff;
    color: #007bff;
    font-size: 1.2rem;
    border: none;
    padding: 10px;
    cursor: pointer;
}

.menu-button:hover {
    background-color: #f8f9fa;
    color: #0056b3;
}

/* Customize Offcanvas styling */
.offcanvas-header {
    background-color: #007bff;
    color: white;
}

.offcanvas-title {
    font-size: 1.5rem;
}

.nav-link {
    font-size: 1.1rem;
    margin: 10px 0;
}

.nav-link:hover {
    background-color: #f0f0f0;
    color: #007bff;
}

.offcanvas-body {
    padding: 20px;
}


.menu-button {
    margin-right: auto; /* Pushes the profile section to the right */
    
}

.profile-section {
    display: flex;
    align-items: center;
    margin-right: 9vw;
    background-color: white;
    padding: 0.5vw;
    border-radius: 10%;
}

.username {
    margin-right: 10px; /* Spacing between username and icon */
    font-weight: bold; /* Bold username for emphasis */
    color:blue;
    margin-left: 1vw;
   
}

.profile-icon {
    color:blue; /* Adjust icon color as needed */
    cursor: pointer; /* Change cursor on hover */
}
