.settings-container {
    margin-top: 50px;
  
    justify-content: center;
    align-items: center;
  }
  
  .settings-title {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .settings-card {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  